import React from 'react'

export const Data = () => {
  return (
    <div className="home-description">

              <h3>hi, i am</h3>
              <h1 className='home__title'>Deepak Krishnan E</h1>
              <h2 className='home__subtitle'>FrontEnd Web Developer | Software Engineer</h2>

              <a href="#contact" className="button button-flex">say hello</a>

    </div>
  )
}

